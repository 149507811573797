
@mixin respond-to($breakpoint) {
    @if map-has-key($responders, $breakpoint) {
        @media #{inspect(map-get($responders, $breakpoint))} {
            @content;
        }
    }
    @else {
        @warn "Breakpoint `#{$breakpoint}` does not exist.";
    }
}


@mixin max-size($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media screen and (max-width: #{map-get($breakpoints, $breakpoint) - 1px}) {
            @content;
        }
    }
    @else {
        @warn "Breakpoint `#{$breakpoint}` does not exist.";
    }
}

@mixin between($bpA, $bpB) {
    @if map-has-key($breakpoints, $bpA) and map-has-key($breakpoints, $bpB) {
        @media screen and #{inspect(map-get($responders, $bpA))} and (max-width: #{map-get($breakpoints, $bpB) - 1px}) {
            @content;
        }
    }
    @else {
        @warn "Breakpoint `#{$bpA}` or `#{$bpB}` does not exist.";
    }
}


$breakpoints: (
    'sm': 550px,
    'md': 551px,
    'lg': 768px,
    'xl': 992px,
    'xxl': 1200px
) !default;

$responders: (
    'sm':  ( max-width:  #{map-get($breakpoints, 'sm')} ),
    'md':  ( min-width:  #{map-get($breakpoints, 'md')} ),
    'lg':  ( min-width:  #{map-get($breakpoints, 'lg')} ),
    'xl':  ( min-width:  #{map-get($breakpoints, 'xl')} ),
    'xxl':  ( min-width:  #{map-get($breakpoints, 'xxl')} )
) !default;


@include max-size('xl') {
    .show-on-desktop { display: none !important;  }
}
@include max-size('lg') {
    .show-on-tablet { display: none !important;  }
}
@include respond-to('xl') {
    .show-on-mobile { display: none !important; }
}


@mixin container() {

    // default for all screens
    margin: 0 auto;
    width: 100%;
    padding: 0px 20px;
    @include respond-to('md') {
        width: 530px;
        padding: 0px 0px;
    }

    &:not(.small) {
        @include respond-to('lg') {
            width: 690px;
        }
        @include respond-to('xl') {
            width: 940px;
        }
        @include respond-to('xxl') {
            width: 1140px;
        }
    }

    &.small *:not(span) {
        margin-bottom: 40px;
        margin-top: 0px;
    }

    &.small h1 {
        margin-bottom: 20px;
    }

    &.small > .formgroup > * {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    &.center {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    &.narrow{
        max-width: 760px;
    }
}

.container {
    @include container;
}


.hide-xl {
    @include max-size('xl') {
        display: none !important;
    }
}

.show-md {
    @include respond-to('md') {
        display: none !important;
    }
}
$base_font_size: 1rem;

$p_font_size_mobile: $base_font_size;
$p_font_size_desktop: 1.0625rem;

$fonts_nav_desktop:                             $base_font_size;
$n_fontsize:                                    $base_font_size;
$fonts_nav_mobile:                              1.125rem;
$h1_desktop:                                    2.625rem;
$h1_tablet:                                     2.25rem;
$h1_small:                                      2.1875rem;
$h1_mobile:                                     1.625rem;
$subheader_size:                                1.25rem;
$h2_desktop:                                    2rem;
$h2_small:                                      1.875rem;
$h2_smaller:                                    1.5625rem;
$h2_mobile:                                     1.4375rem;
$h2_modal:                                      $h2_smaller;
$h3_desktop:                                    1.4rem;
$h3_mobile:                                     1.25rem;
$h3_modal:                                      $h3_desktop;
$h4_desktop:                                    1.125rem;
$h4_mobile:                                     1.125rem;
$kicker:                                        1.125rem;
$kicker_mobile:                                 1rem;
$default_font_weight:                           400;
$kicker_font_weight:                            $default_font_weight;
$btn_font_size:                                 $base_font_size;
$breadcrumb_font_size:                          $base_font_size;
$inp_font_size:                                 $base_font_size;
$p_small_font_size:                             0.875rem;
$blog_h3_desktop:                               1.75rem;
$pricebox_month_size:                           $base_font_size;
$pricebox_yearly_size:                          $base_font_size;
$infobox_fontsize:                              $base_font_size;
$quote_desktop_size:                            1.625rem;
$quote_mobile_size:                             1.125rem;
$primary_font_family:                           'proxima-nova', sans-serif;
$secondary_font_family:                         'utopia-std', serif;
$btn_font_family:                               $primary_font_family;
$p_font_family:                                 $primary_font_family;
$header_font_family:                            $secondary_font_family;



body {
    font-family: $primary_font_family;
    font-size: $base_font_size;
    font-weight: $default_font_weight;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

nav ul li a {
    font-family: $primary_font_family;
    font-weight: 600;

    @include max-size('lg') {
        font-size: $fonts_nav_mobile;
    }
    @include respond-to('lg') {
        font-size: $fonts_nav_desktop;
    }
}

.header-title, h1, h2, h3 {
    font-family: $header_font_family;
}

h1, h2, h3, h4, h5, p {
    margin-top: 0;
}

.header-title,
p.header-title,
h1 {
    font-weight: 700;
    margin-bottom: 48px;

    @include max-size('md') {
        font-size: $h1_mobile;
        line-height: 1.25;
    }
    @include respond-to('md') {
        font-size: $h1_tablet;
    }
    @include respond-to('xl') {
        font-size: $h1_desktop;
        line-height: 1.17;
    }
}

h1.small {
    font-size: $h1_small !important;
}

header {
    .container {
        p.header-title,
        .header-title {
            font-weight: 700;
            margin-top: 0px;
            margin-bottom: 48px;
            font-size: $h1_desktop;
            max-width: 900px;

            @include max-size('md') {
                font-size: $h1_mobile;
                line-height: 1.25;
            }
            @include respond-to('md') {
                font-size: $h1_tablet;
                line-height: 1.17;
            }
            @include respond-to('xl') {
                font-size: $h1_desktop;
                line-height: 1.17;
            }
        }

        h1.kicker, p.kicker {
            font-weight: $kicker_font_weight;
            font-family: $primary_font_family;
            margin-bottom: 16px;
            font-size: $kicker;

            @include max-size('lg') {
                font-size: $kicker_mobile;
            }
        }
    }
}

h2 {
    font-size: $h2_mobile;
    font-weight: 600;
    line-height: 1.3;

    @include respond-to('lg') {
        font-size: $h2_desktop;
    }

    margin-bottom: 20px;

    @include respond-to('xl') {
        margin-bottom: 30px;
    }
}

h2.small {
    @include respond-to('lg') {
        line-height: 35px;
        font-size: $h2-small;
    }
}

h2.smaller {
    font-size: $h2_smaller !important;
}

h2.more-mar-bot {
    margin-bottom: 30px;
    @include respond-to('md') {
        margin-bottom: 40px;
    }
    @include respond-to('lg') {
        margin-bottom: 50px;
    }
    @include respond-to('xl') {
        margin-bottom: 60px;
    }
}

@mixin h3 {
    font-family: $primary_font_family;
    font-size: $h3_desktop;
    font-weight: 600;
    line-height: 1.4;
}

h3 {
    @include h3;
    margin-bottom: 13px;

    @include max-size('lg') {
        font-size: $h3_mobile;
    }
}



h4 {
    // used only in footer
    font-size: $h4_desktop;
    line-height: 1.44;
    margin-top: 0px;
    font-weight: 600;
    font-family: $primary_font_family;

    @include max-size('lg') {
        font-size: $h4_mobile;
    }
}

p:not(.subh1):not(.small) {
    line-height: 1.6;

    &.big {
        font-size: 1rem;
        line-height: 35px;
        font-weight: 500;

        @include respond-to('lg') {
            font-size: 1.125rem;
        }
    }
}

.subh1 {
    line-height: 1.4;
    font-size: $subheader_size;
    margin-bottom: 50px;
}

.fs-small, a.small, p.small, span.small {
    font-size: $p_small_font_size;
}

a {
    font-weight: $default_font_weight;
}

.fs-normal, p, li {
    font-family: $p_font_family;
    font-weight: $default_font_weight;
}

p, li {
    margin: 0px;
}

p.mobile-size, span.mobile-size {
    font-size: $p_font_size_mobile !important;
}

strong {
    font-family: $p_font_family;
    font-weight: 700;
    margin: 0px;
}

input, textarea {
    font-size: $inp_font_size;
}

h2, h3 {
    &:not(:first-child){
        margin-top: 30px;
    }
}

p:not(:last-child){
    margin-bottom: 20px;
}

// article-title-links
.article-link, .article-link:hover, .article-link:active, .article-link:focus, .article-link:visited {
    text-decoration: none;
    outline: 0;
}

.article-link > h3 {
    @include transition();
}

@mixin small-h2 {
    font-size: $h2_small;
    font-weight: 600;
    font-family: $header_font_family;
}

@mixin modal_h2 {
    font-size: $h2_modal;
    font-weight: 600;
    font-family: $header_font_family;
}

@mixin modal_h3 {
    font-size: $h3_modal;
    font-weight: 600;
    font-family: $header_font_family;
}

.small-h2 { @include small-h2; }

.modal-overlay .modal-title {
    @include h3;
    margin-bottom: 25px;
    display: block;
}

section.blog-article {
    @include respond-to('lg') {
        h3 {
            font-size: $blog_h3_desktop;
            font-weight: 700;
        }
    }
}

.semi-bold {
    font-weight: 600 !important;
}

.bold {
    font-weight: 700 !important;
}
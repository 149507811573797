
.login-box {
    max-width: 380px;
    margin: calc(40vh - 100px) auto 0 auto;
    background-color: color(white);
    padding: 50px;
    @include border-radius;
    img {
        max-width: 130px;
    }
}
.searchbar {
    display: flex;
    width: 100%;
    justify-content: stretch;

    .btn3 {
        margin-left: 20px;
        white-space: nowrap;
    }
}

.search-result-container {
    position: absolute;
    padding-top: 225px;
    left: 0;
    padding-left: 60px;
    padding-right: 60px;
    right: 0;
    &::-webkit-scrollbar {
        display: none;
    }
}

.search-result-list {
    &:hover .search-result-list-item:not([role='resultheader']) {
        opacity: 0.6;
    }
    margin-bottom: 40px;
}

.search-result-list-item {
    position: relative;

    &[role='resultheader'] {
        background: unset;
        position: fixed;
        top: 210px;
        left: calc(#{$sidebar_width} + 60px);
        right: 60px;
        z-index: 2;
        background: color(bg_gray);
        color: color(dark_gray);
        font-size: $p_small_font_size;
    }

    border-top: 10px solid color(bg_gray);
    background: color(white);
    padding: 20px 30px;
    display: grid;
    grid-template-columns: 40px minmax(200px, 1fr) minmax(200px, 1fr) minmax(100px, 1fr) minmax(
            100px,
            1fr
        ) 40px;
    column-gap: 2rem;
    white-space: nowrap;
    @include transition(all, 0.12s, ease);
    @include border-radius();

    &:not([role='resultheader']):hover {
        cursor: pointer;
        opacity: 1 !important;
    }

    .settings {
        @include transition;
        opacity: 0.6;
        cursor: pointer;
        &:hover {
            opacity: 0.9;
        }
    }

    .end {
        justify-self: end;
    }
}

.search-container {
    position: fixed;
    z-index: 10;
    background: color(bg_gray);
    padding: 50px 60px;
    top: 0;
    left: #{$sidebar_width};
    right: 0;
}

.no-pad {
    padding: 0;
}
.no-pad-bot {
    padding-bottom: 0px;
}
.no-pad-top {
    padding-top: 0px;
}
.no-v-pad {
    padding-bottom: 0px;
    padding-top: 0px;
}

.pad-bot-40 {
    padding-bottom: 40px;
}
.pad-bot-30 {
    padding-bottom: 30px;
}
.pad-bot-20 {
    padding-bottom: 20px;
}
.pad-bot-10 {
    padding-bottom: 10px;
}

.pad-top-50 {
    padding-top: 50px;
}
.pad-top-40 {
    padding-top: 40px;
}
.pad-top-30 {
    padding-top: 30px;
}
.pad-top-20 {
    padding-top: 20px;
}
.pad-top-10 {
    padding-top: 10px;
}

.pad-10 {
    padding: 10px !important;
}
.pad-20 {
    padding: 20px !important;
}
.pad-80 {
    padding: 80px !important;
}

.no-mar {
    margin: 0px !important;
}

.no-mar-bot {
    margin-bottom: 0px !important;
}
.mar-bot-80 {
    margin-bottom: 80px !important;
}
.mar-bot-60 {
    margin-bottom: 60px !important;
}
.mar-bot-50 {
    margin-bottom: 50px !important;
}
.mar-bot-40 {
    margin-bottom: 40px !important;
}
.mar-bot-30 {
    margin-bottom: 30px !important;
}
.mar-bot-25 {
    margin-bottom: 25px !important;
}
.mar-bot-20 {
    margin-bottom: 20px !important;
}
.mar-bot-15 {
    margin-bottom: 15px !important;
}
.mar-bot-10 {
    margin-bottom: 10px !important;
}
.mar-bot-8 {
    margin-bottom: 10px !important;
}

.nm {
    margin: 0;
}
.mar-10 {
    margin: 10px !important;
}
.mar-20 {
    margin: 20px !important;
}
.mar-30 {
    margin: 30px !important;
}
.mar-40 {
    margin: 40px !important;
}

.neg-mar-10 {
    margin: -10px !important;
}
.neg-mar-20 {
    margin: -20px !important;
}
.neg-mar-30 {
    margin: -30px !important;
}
.neg-mar-40 {
    margin: -40px !important;
}

.mar-top-0 {
    margin-top: 0px !important;
}
.mar-top-10 {
    margin-top: 10px !important;
}
.mar-top-20 {
    margin-top: 20px !important;
}
.mar-top-30 {
    margin-top: 30px !important;
}
.mar-top-40 {
    margin-top: 40px !important;
}
.mar-top-80 {
    margin-top: 80px !important;
}

.neg-mar-bot-10 {
    margin-bottom: -10px !important;
}

.mar-right-50 {
    margin-right: 50px !important;
}

.mar-left-10 {
    margin-left: 10px !important;
}

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.col {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}

.grid2 {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 20px;

    &.xl {
        @include respond-to('xl') {
            margin-bottom: 0px;
            grid-gap: 20px;
            grid-template-columns: 1fr 1fr;
        }
    }

    &.xxl {
        @include respond-to('xxl') {
            margin-bottom: 0px;
            grid-gap: 20px;
            grid-template-columns: 1fr 1fr;
        }
    }

    &:not(.xl):not(.xxl):not(.mobile) {
        @include respond-to('lg') {
            margin-bottom: 0px;
            grid-gap: 20px;
            grid-template-columns: 1fr 1fr;
        }
    }

    &.mobile {
        grid-template-columns: 1fr 1fr;
    }
}

.grid4 {
    display: grid;
    grid-template-columns: 1fr;

    @include respond-to('lg') {
        margin-bottom: 0px;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
    }

    @include respond-to('xl') {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.grid-c-gap-60 {
    column-gap: 60px !important;
}

.grid-c-gap-50 {
    column-gap: 50px !important;
}

.grid-r-gap-40 {
    row-gap: 40px !important;
}

.grid-c-gap-30 {
    column-gap: 30px !important;
}

.grid-c-gap-20 {
    column-gap: 20px !important;
}

.grid-no-row-gap {
    row-gap: 0px !important;
}

.grid-no-column-gap {
    column-gap: 0px !important;
}

.grid-no-gap {
    row-gap: 0px !important;
    column-gap: 0px !important;
}

.col-span-2 {
    grid-column: span 2;
}

.row-span-2 {
    grid-row: span 2;
}

.shadow0 {
    box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.1);
}

.no-border {
    border: none !important;
}

.border-radius {
    @include border-radius();
}

.border-bottom {
    border-bottom: 1px solid gray;
}

.all-inline-block {
    & > * {
        display: inline-block !important;
    }
}

.all-block > * {
    display: block;
    width: 100%;
}

.block {
    display: block;
}

.full-width {
    width: 100%;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.italic {
    font-style: italic;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.flex {
    display: flex !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-space-evenly {
    display: flex;
    justify-content: space-evenly !important;
}

.flex-right {
    display: flex;
    justify-content: right;
}

.float-left {
    float: left;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.flex-stretch {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex: 1;
    width: 100%;
    * {
        flex: 1;
    }
}

.light-05 {
    color: rgba(color(white), 0.5) !important;
}
.light-07 {
    color: rgba(color(white), 0.7) !important;
}
.black-05 {
    color: rgba(color(black), 0.5) !important;
}
.flex-end {
    align-self: flex-end;
}
.flex-align-center {
    align-items: center;
}
.flex-justify-center {
    justify-content: center;
}

.big-paragraph {
    p {
        font-size: 1rem !important;

        @include respond-to('lg') {
            font-size: 1.125rem !important;
        }
    }
    font-size: 1rem !important;

    @include respond-to('lg') {
        font-size: 1.125rem !important;
    }
}

.no-decoration {
    text-decoration: none !important;
}

.underline {
    text-decoration: underline;
}

.line-through {
    text-decoration: line-through;
}

.relative {
    position: relative;
}

.pointer {
    cursor: pointer;
}

.no-display {
    display: none;
}

.no-pointer-events {
    pointer-events: none;
}

.float-right {
    float: right;
}

.w-50 {
    width: 50px !important;
}
.w-60 {
    width: 60px !important;
}
.w-70 {
    width: 70px !important;
}
.max-w-200 {
    max-width: 200px !important;
}

.no-wrap {
  white-space: nowrap;
}
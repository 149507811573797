.detailview-box.interest-list-information {
    .grid {
        grid-template-columns: 1fr 1fr;
    }
}

.detailview-box.interest-list-information {
    .custom-select-wrapper {
        grid-template-columns: 1fr;
        .custom-options {

        }
    }
}
.detailview-box.interest-list {
  & > .grid {
      grid-template-columns: 0.5fr;
      column-gap: 80px;
      row-gap: 25px;

      & > div {
          background-color: color(bg_gray);
          padding: 8px 20px;
          @include border-radius;
          display: grid;
          column-gap: 20px;
          grid-template-columns: 1fr 120px 30px;
          align-items: center;

          .remove-icon-btn {
              cursor: pointer;
          }

          p:hover {
              text-decoration: underline;
              cursor: pointer;
          }
      }
  }
}

.member-list-item {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .name {

    }
    .email {

    }
}
// color variable map
$colors: (
    n_succes:           #d1e5e6,
    n_error:            #f2d7da,
    n_info:             #f9ebd3,
    light_alligator:    #e8f5f6,
    alligator:          #088178,
    salmon_red:         #FF5B60,
    invalid_red:        #f00,
    dark_red:           #e00a12,
    white:              #ffffff,
    infobox_bg:         #f0f5fd,
    infobox_border:     #9DB6C4,
    light_blue:         #d9e9f8,
    forest:             #003c42,
    black:              #313131,
    blackblack:         #1d1d1d,
    gray:               #6b6b6b,
    dark_gray:          #717171, // also used in icons with suffix '-gray.svg'
    waitinglist_gray:   #818181,
    placeholder_gray:   #aaaaaa,
    darker_light_gray:  #bbb,
    border_gray:        #dbdbdb,
    switch_gray:        #e5e5e5,
    dark_bg_gray:       #e9e9e9,
    bg_gray:            #f7f7f7,
    modal_bg:           rgba(0, 0, 0, 0.5),
    overlay0:           rgba(0, 0, 0, 0.3),
    yellow:             #eda200,
    sand:               #fee4c3,
    light_05:           rgba(255, 255, 255, 0.5),
    light_02:           rgba(255, 255, 255, 0.2),
    dark_05:            rgba(0, 0, 0, 0.5),
    bg_aqua:             #eaf4f3,
);


// `color(primary)`
@function color($color-name) {
    @return map-get($colors, $color-name);
}

.gray-link a, a.gray-link, .gray-link a:visited, a.gray-link:visited  {
    color: color(dark_gray);
}

.white-link a, a.white-link, .white-link a:visited, a.white-link:visited  {
    color: color(white);
}

.green-link a, .green-link.a:visited{
    color: color(alligator);
}

span.green-link {
    color: color(alligator);
    cursor: pointer;
}

.gray { color: color(dark_gray) !important; }
.red { color: color(invalid_red) !important; }
.alligator { color: color(alligator) !important; }
.black { color: color(black) !important; }

p, h1, h2, h3, h4, h5, strong, li {
    color: color(black);
}

header h1, header .kicker {
    color: color(white);
}

header .header-title {
    color: color(white);
}

// article-title-links
.article-link:hover > h3 {
    color: color(alligator);
}

/*
    below loop generates color classes for each kvp in $colors, eg:

    .white { color: #fff; }
    .white-bg {background-color: #fff}
    .white-border {border-color: #fff}
*/
@each $name, $color in $colors {

    .#{"" + $name} {
        color: $color;
    }

    .#{"" + $name}-bg {
        background-color: $color;
    }

    .#{"" + $name}-border {
        border-color: $color;
    }
}

.gray-bg {
    background-color: color(bg_gray);
}

.red { color: color(invalid_red); }
.white-text { color: color(white) !important; }

p:not(.gray) {
    a, a:active, a:focus, a:visited, a:hover {
        color: color(alligator);
        text-decoration: underline;
    }
}
/*
 *      Real estate detail view
 */

.detailview-box.real-estate-information {
    .grid {
        &.single {
            grid-template-columns: 1fr;
        }

        grid-template-columns: 1fr 1fr;
    }
}

.detailview-box {
    &.real-estate-documents {
        &>.grid {
            grid-template-columns: 1fr 1fr;
            column-gap: 80px;
            row-gap: 25px;

            &>a {
                @include border-radius;
                color: inherit;
                text-decoration: none;
                border: 1px transparent solid;
                background-color: color(bg_gray);
                padding: 15px 20px;
                display: grid;
                column-gap: 20px;
                grid-template-columns: 1fr 30px;
                align-items: center;

                &:hover>div {
                    text-decoration: underline;
                }
            }
        }
    }

    &.real-estate-organization {
        &>.grid {
            grid-template-columns: 1fr 1fr;
            column-gap: 80px;
            row-gap: 25px;

            &>div {
                background-color: color(bg_gray);
                padding: 15px 20px;
                @include border-radius;
                display: grid;
                column-gap: 20px;
                grid-template-columns: 1fr 30px;
                align-items: center;
                cursor: pointer;

                &:hover p {
                    text-decoration: underline;
                }
            }
        }
    }

    &.spots {
        .clickable {
            cursor: pointer;
        }

        &>.grid {
            @include transition(all, 0.12s, ease);
            @include border-radius();

            gap: 0 10px;

            grid-template-columns: 1fr 3fr 3fr 2fr 1fr 1fr;
            margin-top: 0.5rem;
            padding: 15px 20px;

            &.header {
                font-weight: 600;
            }

            &:not(.header) {
                background-color: color(bg_gray);

                &:hover {
                    background-color: color(dark_bg_gray);
                }

                div {
                    display: flex;
                    align-items: center;

                    &.full {
                        justify-content: center;
                        grid-column: 1/-1;
                    }
                }

                div:first-of-type:not(.full) {
                    justify-content: center;
                    border: 1px gray solid;
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 99999px;
                    font-weight: 600;
                }
            }

            a {
                color: inherit;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            >img {
                height: 100%;
                width: 25px;
                justify-self: end;
                cursor: pointer;
            }
        }
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes slide-up {
    0% {
        opacity: 0;
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slide-down {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(50px);
        transform: translateY(50px);
    }
}

*:focus {
    outline: 0 !important;
}

*::-moz-focus-inner {
    border: 0;
}

a {
    cursor: pointer;
}

header,
section,
footer {
    position: relative;
    z-index: 1;
}

header:not(.top) {
    padding: 100px 0;
    color: color(white);

    @include respond-to('lg') {
        padding: 160px 0px;
    }
}

header > *:last-child {
    margin-bottom: 0 !important;
}

header > .container > *:last-child {
    margin-bottom: 0 !important;
}

header .subh1 {
    max-width: 630px;
    color: color(white);
}

section {
    padding: 70px 0px;

    &:last-of-type:not(.get_newsletters) {
        margin-bottom: 0 !important;
    }

    @include max-size('lg') {
        padding: 50px 0px;
    }

    @include respond-to('xl') {
        padding: 100px 0px;
    }

    &.graybg {
        background-color: color(bg_gray);
    }

    &.dark {
        background-color: color(blackblack) !important;
        h2,
        h3,
        h4 {
            color: color(white);
        }
        .article-grid-item {
            h2,
            h3,
            h4 {
                color: color(white);
            }
            .article-excerpt {
                color: color(white);
                p {
                    color: color(white);
                }
            }

            .article-date {
                color: color(light_05);
            }
        }
    }

    .article-grid-item {
        .article-date {
            color: color(dark_05);
        }
    }

    &.forest-section {
        .article-grid-item {
            .article-date {
                color: color(light_05);
            }
        }

        background-color: color(forest);

        h1,
        h2,
        h3,
        h4 {
            color: color(white);
        }

        p {
            color: color(white);
        }
    }
}

section.grid_2x3 {
    .grid {
        grid-gap: 56px;

        @include respond-to('sm') {
            grid-template-columns: 1fr;
        }
        @include respond-to('md') {
            grid-template-columns: 1fr 1fr;
        }
        @include respond-to('xl') {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@mixin btn {
    padding: 14px 28px;
    border: none;
    text-align: center;
    @include border-radius(4px);
    transition: 
        background-color 200ms ease-in-out,
        border-color 200ms ease-in-out;
    text-decoration: none;
    font-size: $btn_font_size;
    font-family: $btn_font_family;
    font-weight: 600;
    opacity: 1;
    cursor: pointer;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;

    @include respond-to('sm') {
        display: block;
        width: 100%;
    }

    &.small {
        display: inline-block;
        padding: 8px 8px;
        cursor: pointer;
        border-style: solid;
        border-width: 1px;
        font-weight: 700;
        font-size: $p_small_font_size;
    }

    &.icon {
        padding-left: 52px;

        & > img.icon {
            position: absolute;
            margin-left: -30px;
        }
    }
}

@mixin btn1 {
    @include btn;
    background-color: color(salmon_red);
    color: color(white);

    &:hover {
        background-color: lighten(color(salmon_red), 2%);
    }

    &[disabled] {
        opacity: 0.5;
    }

    &.outlined {
        border: 1px solid color(salmon_red);
        color: color(salmon_red);
        background-color: transparent;

        &:hover {
            color: lighten(color(salmon_red), 5%);
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    &.text-button {
        background-color: transparent;
        border: none;
        color: color(salmon_red);
        margin: 0px -28px;
    }
}

@mixin btn2 {
    @include btn;
    background-color: color(forest);
    color: color(white);

    &:hover {
        background-color: lighten(color(forest), 2%);
    }

    &.outlined {
        border: 1px solid color(forest);
        color: color(forest);
        background-color: transparent;

        &:hover {
            color: lighten(color(forest), 5%);
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    &.text-button {
        background-color: transparent;
        border: none;
        color: color(forest);
        margin: 0px -28px;
    }
}

@mixin btn3 {
    @include btn;
    background-color: color(alligator);
    color: color(white);

    &:not([disabled]):hover {
        background-color: lighten(color(alligator), 2%);
    }

    &[disabled] {
        background-color: color(border_gray);
        cursor: default;
    }

    &.outlined {
        border: 1px solid color(alligator);
        color: color(alligator);
        background-color: transparent;

        &:hover {
            color: lighten(color(alligator), 5%);
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    &.checkbox-button {
        border: 1px solid color(alligator);
        background-color: transparent;
        white-space: nowrap;
        border-radius: 999px;
        color: color(white);
        border-color: color(light_02);
        border-width: 1px;

        &:hover {
            color: color(white);
            background-color: transparentize(color(white), 0.9);
        }

        &[disabled] {
            opacity: 0.5;
        }

        &.selected {
            background: color(alligator);
            border-color: color(alligator);
        }
    }

    &.text-button {
        background-color: transparent;
        border: none;
        color: color(alligator);
    }
}

@mixin btn4 {
    @include btn;
    background-color: color(white);
    color: color(black);
    border: 1px solid color(border_gray);
    padding: 13px 27px;

    &:hover {
        border: 1px solid color(darker_light_gray);
    }

    &[disabled] {
        opacity: 0.6;
        pointer-events: none;
    }

    &.outlined {
        border: 1px solid color(border_gray);
        color: color(alligator);
        background-color: transparent;

        &:hover {
            color: lighten(color(border_gray), 5%);
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    &.text-button {
        background-color: transparent;
        border: none;
        color: color(dark_gray);
    }
    
    &.selected {
        color: color(alligator);
        border-color: color(alligator);
    }
}

@mixin btn5 {
    @include btn;
    background-color: transparent;
    color: color(white);
    border: 2px solid color(white);
    padding-top: 12px;
    padding-bottom: 12px;

    &:hover {
        color: color(black);
        background-color: color(white);
    }

    &[disabled] {
        opacity: 0.6;
        pointer-events: none;
        background-color: transparent;
        color: color(white);
    }
}

@mixin btn6 {
    @include btn;
    background-color: transparent;
    color: color(black);
    border: 1px solid color(border_gray);
    padding-top: 12px;
    padding-bottom: 12px;
}

/*
    btn7:
    - transparent bh
    - thick black border
    - solid bg on hover
*/
@mixin btn7 {
    @include btn;
    border: 2px solid color(black);
    background-color: transparent;
    color: color(black);
    padding-top: 12px;
    padding-bottom: 12px;

    &:hover {
        color: color(white);
        background-color: color(black);
    }
}
@mixin btn8 {
    @include btn;
    background-color: color(white);
    color: color(black);
    padding-top: 12px;
    padding-bottom: 12px;
}

@mixin button-spinner($color, $bg_color) {
    position: relative;

    $border_width: 2;
    $size: 14;

    &::before {
        content: '';
        left: 0;
        top: 0;
        background-color: transparentize($bg_color, 0.05);
        border-radius: inherit;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        opacity: 0;
        @include transition(opacity);
    }

    &::after {
        position: absolute;
        content: '';
        width: $size + px;
        height: $size + px;
        border: solid transparent;
        border-top: $border_width + px solid color(color);
        border-bottom: $border_width + px solid color(color);
        border-radius: 999px;
        right: calc(50% - #{($size / 2) + $border_width + px});
        top: calc(50% - #{($size / 2) + $border_width + px});
        z-index: 2;
        opacity: 0;
        @include transition(opacity);
        animation: spin 1500ms infinite ease-in-out;
        animation-play-state: paused;
    }

    &.loading::before,
    &.loading::after {
        opacity: 1;
    }

    &.loading::after {
        animation-play-state: running;
    }

    &.outlined::before {
        background-color: transparentize($color, 0.05);
    }

    &.outlined::after {
        border-top: $border_width + px solid $bg_color;
        border-bottom: $border_width + px solid $bg_color;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.btn1 {
    @include btn1;
    @include button-spinner(color(white), color(salmon_red));
}
.btn2 {
    @include btn2;
    @include button-spinner(color(white), color(forest));
}
.btn3 {
    @include btn3;
    @include button-spinner(color(white), color(alligator));
}
.btn4 {
    @include btn4;
    @include button-spinner(color(white), color(white));
}
.btn5 {
    @include btn5;
    @include button-spinner(color(white), color(white));
}
.btn6 {
    @include btn6;
    @include button-spinner(color(black), color(white));
}
.btn7 {
    @include btn7;
    @include button-spinner(color(black), color(white));
}
.btn8 {
    @include btn8;
    @include button-spinner(color(black), color(white));
}

.btn-icon {
    margin-bottom: -3px;
    margin-right: 10px;
}

a,
a:focus,
a:visited,
a:active,
a:hover {
    &.btn1 {
        @include btn1;
    }
}
a,
a:focus,
a:visited,
a:active,
a:hover {
    &.btn2 {
        @include btn2;
    }
}
a,
a:focus,
a:visited,
a:active,
a:hover {
    &.btn3 {
        @include btn3;
    }
}
a,
a:focus,
a:visited,
a:active,
a:hover {
    &.btn4 {
        @include btn4;
    }
}
a,
a:focus,
a:visited,
a:active,
a:hover {
    &.btn5 {
        @include btn5;
    }
}
a,
a:focus,
a:visited,
a:active,
a:hover {
    &.btn6 {
        @include btn6;
    }
}
a,
a:focus,
a:visited,
a:active,
a:hover {
    &.btn7 {
        @include btn8;
    }
}
a,
a:focus,
a:visited,
a:active,
a:hover {
    &.btn8 {
        @include btn7;
    }
}

.share-btn {
    background-image: url('/static/icons/share-gray.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: right 18px center;
    @include border-radius(100px);
    background-color: color(bg_gray);
    padding: 12px 47px 12px 25px;
    border: none;
    color: color(dark_gray);
    cursor: pointer;
}

@mixin small-btn {
    display: inline-block;
    padding: 6px 23px;
    @include border-radius(100px);
    cursor: pointer;
    border-style: solid;
    border-width: 2px;
    font-weight: 500;
    font-size: $p_small_font_size;
}

.small-btn1 {
    @include small-btn;
    background-color: color(black);
    color: color(white);
    border-color: color(black);
}
.small-btn2 {
    @include small-btn;
    background-color: transparent;
    color: color(black);
    border-color: color(border_gray);
}

.grid {
    display: grid;
    width: 100%;
    p {
        word-break: break-word;
    }
    &.grid-2-column {
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
    }

    &[class^='responsive-2-column-'] {
        grid-template-columns: 1fr;
    }

    &.responsive-2-column-md {
        @include respond-to('md') {
            grid-template-columns: 1fr 1fr;
        }
    }

    &.responsive-2-column-lg {
        @include respond-to('lg') {
            grid-template-columns: 1fr 1fr;
        }
    }

    &.responsive-2-column-lg-5-3 {
        @include respond-to('lg') {
            grid-template-columns: 5fr 3fr;
        }
    }

    &.gap-20 {
        grid-gap: 20px;
    }

    &.gap-30 {
        grid-gap: 30px;
    }

    &.gap-50 {
        grid-gap: 50px;
    }

    &.gap-50-100 {
        grid-gap: 50px 100px;
    }

    &.align-items-center {
        align-items: center;
    }

    &.resp-2-col-vert-cent {
        grid-template-columns: 1fr;
        align-items: center;
        grid-gap: 50px 100px;

        @include respond-to('lg') {
            grid-template-columns: 1fr 1fr;
        }
    }
}

img {
    max-width: 100%;
    @include border-radius();
}

picture {
    font-size: 0;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
textarea {
    font-family: 'proxima-nova', sans-serif;
    @include border-radius();
    @include transition();
    padding: 14px 18px;
    border: 1px solid color(border_gray);
    min-width: 150px;
    -webkit-appearance: none;

    &.invalid {
        border: 1px solid color(invalid_red);
    }

    &.long {
        min-width: 300px;
    }

    &.flex-1 {
        flex: 1;
        width: 100%;
    }

    &.full-width {
        width: 100%;
    }

    &.block {
        display: block;
        width: 100%;
    }

    &.search {
        padding-left: 42px !important;
        background-image: url('/static/icons/search.svg') !important;
        background-repeat: no-repeat;
        background-size: 17px 17px;
        background-position: 15px 16px;
    }

    &[disabled] {
        background-color: color(border_gray);
    }
}

::placeholder {
    color: color(placeholder_gray);
    opacity: 1;
}
:-ms-input-placeholder {
    color: color(placeholder_gray);
}
::-ms-input-placeholder {
    color: color(placeholder_gray);
}

.invalid + span.check-square {
    border-color: color(invalid_red);
}

.checkbox {
    display: grid !important;
    justify-content: center;
    grid-template-columns: min-content 1fr;
    align-items: center;
    grid-gap: 10px;
    cursor: pointer;
    position: relative;
    align-self: center;

    .check-square {
        @include border-radius();
        @include transition();
        background-color: color(white);
        border: 1px solid color(border_gray);
        padding: 13px;
    }

    svg {
        display: none;
        position: absolute;
        stroke-width: 1.5;
        stroke-linecap: round;
        stroke-linejoin: round;
        left: 5px;
        height: 18px;
        width: 18px;
    }

    &.invalid + .check-square {
        border-color: color(invalid_red);
    }

    input {
        display: none;
    }

    input:checked ~ svg {
        display: inline-block;
    }

    .check-square {
        position: relative;
    }

    p {
        margin-left: 0 !important;
    }
}

.radiobtn {
    cursor: pointer;
    position: relative;
    margin: 10px 0px;
    display: block;
}

.radiobtn input {
    display: none;
}

.radiobtn p {
    display: inline-block;
    position: relative;
    margin: 0px 0px 10px 0px !important;
    left: 41px;
    top: 0px;
}

.radiobtn span.radio-inner-circle {
    @include transition();
    @include border-radius(50%);
    position: absolute;
    display: block;
    top: 9px;
    left: 9px;
    height: 10px;
    width: 10px;
    background-color: color(white);
    opacity: 0;
}

.radiobtn input:checked ~ span.radio-inner-circle {
    opacity: 1;
    background-color: color(white);
}

.radiobtn input:checked ~ span.radio-outer-circle {
    opacity: 1;
    background-color: color(alligator);
    border: 1px solid color(alligator);
}

.radiobtn .radio-outer-circle {
    @include border-radius();
    @include transition();
    background-color: color(white);
    border: 1px solid color(border_gray);
    padding: 13px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.radiobtn.invalid .radio-outer-circle {
    border-color: color(invalid_red);
}

.radiobtn .radio-outer-circle {
    @include border-radius(50%);
}

.header-overlay0 {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-color: color(overlay0);
    z-index: -1;
}

/*
 *      DATEPICKER
 */

.datepicker {
    height: 52px;
    background-image: url('/static/icons/calendar.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 13px) center;
}

.qs-datepicker-container {
    font-family: $primary_font_family;
    border-color: color(light-gray);
    color: color(black);
    @include box-shadow();
    padding: 10px;
    width: 290px;
}
.qs-controls {
    background-color: color(white);
    padding: 14px 0px 4px;
}

.qs-arrow {
    @include border-radius(0px);
}

.qs-square {
    @include border-radius(0px);
}

.qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover {
    background: transparentize(color(alligator), 0.6);
}

/*
 *      SMOOTH IOS-LIKE SWITCHES
 */

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 26px;
    vertical-align: middle;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: color(bg_gray);
    @include transition(all, 0.2s, ease-in-out);
    @include border-radius(20px);
}

.slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 2px;
    bottom: 2px;
    background-color: color(white);
    @include transition(all, 0.2s, ease-in-out);
    @include border-radius(50%);
    @include box-shadow(0, 0, 5px, 0, rgba(0, 0, 0, 0.3));
}

input[type='checkbox']:checked + .slider {
    background-color: color(alligator);
}

input[type='checkbox']:focus + .slider {
    /*box-shadow: 0 0 1px #2196F3;*/
    outline: 0;
}

input[type='checkbox']:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
}

/*
 *      SPINNER
 */

.spinner {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: color(modal_bg);
    opacity: 0;
    z-index: -1;
    -webkit-transition: opacity 0.3s ease, z-index 0.3s step-end;
    transition: opacity 0.3s ease, z-index 0.3s step-end;
    align-items: center;
    justify-content: space-around;

    &.active {
        opacity: 1.1;
        z-index: 130;
        transition-timing-function: ease, step-start;
    }
}

.sk-chase {
    width: 60px;
    height: 60px;
    position: relative;
    animation: sk-chase 2.5s infinite linear both;
    z-index: 100;
}

.sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2s infinite ease-in-out both;

    &::before {
        content: '';
        display: block;
        width: 25%;
        height: 25%;
        background-color: color(alligator);
        border-radius: 100%;
        animation: sk-chase-dot-before 2s infinite ease-in-out both;
    }
}

.sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
    animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
    animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s;
}

@keyframes sk-chase {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes sk-chase-dot {
    80%,
    100% {
        transform: rotate(360deg);
    }
}
@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4);
    }
    100%,
    0% {
        transform: scale(1);
    }
}

.spinner.webapp {
    background-color: color(bg_gray);

    .sk-chase-dot::before {
        background-color: color(alligator);
    }
}

/*
 *      CUSTOM DROPDOWNS
 */

.custom-select-wrapper {
    font-family: 'proxima-nova', sans-serif;
    position: relative;
    user-select: none;
    width: 100%;

    &.disabled {
        opacity: 0.6;
    }
}
.custom-select-wrapper.invalid .custom-select {
    border: 1px solid color(invalid_red);
}
.custom-select {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid color(border_gray);
    @include border-radius;
    margin: -1px;
    padding: 1px;
}
.custom-select-wrapper.disabled .custom-select__trigger {
    cursor: default;
}
.custom-select__trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;
    color: color(black);
    height: 40px;
    background: color(white);
    cursor: pointer;

    .value {
        margin: 0;
    }

    &.small {
        padding: 0 12px;
        height: 35px;
    }
}
.custom-options {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid color(border_gray);
    background: #fff;
    transition: all 0.4s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 2;
    margin-top: 8px;
    @include border-radius;
    @include box-shadow;
}
.custom-select.open .custom-options {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
}
.custom-option {
    position: relative;
    display: block;
    padding: 0 22px 0 22px;
    font-size: inherit;
    color: color(black);
    line-height: 45px;
    cursor: pointer;
    transition: all 0.5s;
    &:not(:first-child) {
        border-top: 1px solid color(border_gray);
    }
}
.custom-option:hover {
    cursor: pointer;
    background-color: color(bg_gray);
}
.custom-option.selected {
    color: color(black);
    background-color: color(bg_gray);
}

.arrow {
    position: relative;
}
.arrow::after {
    content: '';
    transition: all 0.2s ease;
    position: absolute;
    transform: rotate(180deg);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 6px solid color(black);
    top: -3px;
    right: 0px;
}

.open .arrow::after {
    transform: rotate(0deg);
}

.floating-label-input-combined {
    font-size: 0px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.floating-label-input-combined > .floating-label-input {
    &:first-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    &:last-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        margin-left: -1px;
    }

    & > label {
        left: 15px;
    }

    min-width: 80px;

    & > input {
        min-width: 80px;
    }
}

.floating-label-input {
    @include border-radius();
    @include transition();
    position: relative;
    border: 1px solid color(border_gray) !important;
    min-width: 150px;
    display: flex;
    overflow: hidden;
    padding: 15px;
    margin-bottom: 15px;
    min-height: 50px;
}

.floating-label-date {
    overflow: visible !important;
}

.floating-label-input > .floating-input-label--input,
.floating-label-input > input,
.floating-label-input > textarea {
    color: color(black);
    font-family: 'proxima-nova', sans-serif !important;
    border: none;
    outline: 0;
    margin: -15px !important;
    padding: 20px 15px 10px 15px !important;
    flex: 1;
    -webkit-appearance: none;
}

.floating-label-input > textarea {
    min-height: 210px;
}

.floating-label-input > .floating-input-label--label.float,
.floating-label-input > label.float {
    color: color(black);
    font-size: 0.6875rem;
    transform: translate(0, -10px);
}

.floating-label-input > .floating-input-label--label,
.floating-label-input > label {
    position: absolute;
    font-family: 'proxima-nova', sans-serif !important;
    color: color(placeholder_gray) !important;
    pointer-events: none;
    transition: 0.2s ease all;
    z-index: 1;
    font-size: $base_font_size;
}

/*
    .icon-and-text-container
    usage:

    <div class="icon-and-text-container">
        <img class="icon" src="/static/icons/apartments.svg" />
        <div class="text">ca. 100 lejligheder</div>
    </div>

*/
.icon-and-text-container {
    display: grid;
    grid-template-columns: 34px 1fr;

    .line {
        display: block;
        margin: 0;
    }

    .line:not(:last-of-type) {
        margin-bottom: 5px;
    }
}

/*
    .hr
    usage:

    <div class="hr mar-bot-20"></div>
*/
.hr {
    width: 100%;
    background: color(border_gray);
    height: 1px;
}

.hr-small {
    width: 75%;
    margin-right: 12.5%;
    margin-left: 12.5%;
    background: color(dark_bg_gray);
    height: 1px;
}

.display-below-xl {
    @include respond-to('xl') {
        display: none;
    }
}

/*
    the patented Dyrholm-hover
*/
.lassekasse-hover {
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    -webkit-transition: transform 0.3s ease, box-shadow 0.3s ease;

    box-shadow: none;

    &:hover {
        transform: translateY(-1px);
        box-shadow: 0 60px 90px -30px rgba(31, 36, 48, 0.1);
    }
}

/*
    floating (absolute) dropdowns. Requires some js
*/

.floating-dropdown {
    position: absolute;
    z-index: 111;
    list-style-type: none;
    padding: 19px 20px;
    background-color: #fff;
    @include border-radius;
    @include box-shadow();
    @include transition(opacity, 0.3s);
    opacity: 1;

    a {
        display: block;
        width: 100%;
        padding: 8px 0px;
        cursor: pointer;
        color: color(black);

        &:hover {
            color: color(dark_gray);
        }
    }

    li.text-coral {
        a {
            &:hover {
                color: color(salmon_red);
            }
        }
    }

    .seperator {
        border-bottom: 1px solid #dbdbdb;
        display: block;
        margin: 10px 0px;
    }
}

/*
    Notifications (requires some js and html)
*/

.n-container {
    position: fixed;
    top: 46px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    z-index: 11100000000000;
    transform: translateX(-50%);
}

.n {
    padding: 16px 32px 16px 59px;
    @include transition();
    margin-bottom: 15px;
    @include border-radius;
    animation-iteration-count: 1;
    cursor: pointer;
    font-size: $n_fontsize;

    &.fadeOut {
        animation-duration: 0.4s;
        animation-name: fadeOut;
        opacity: 0;
    }

    &.fadeIn {
        animation-duration: 0.3s;
        animation-name: fadeIn;
        opacity: 1;
    }

    &.succes {
        background-color: color(n_succes);
    }
    &.error {
        background-color: color(n_error);
    }
    &.alert {
        background-color: color(n_info);
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        left: 22px;
    }

    &.succes::before {
        background-image: url('/static/icons/n/check.svg');
    }
    &.error::before {
        background-image: url('/static/icons/n/bug.svg');
    }
    &.alert::before {
        background-image: url('/static/icons/n/info.svg');
    }
}

.confirm {
    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9999999999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .confirm-box {
        min-height: 150px;
        max-width: 350px;
        border-radius: 4px;
        background-color: #ffffff;
        border-radius: 4px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        opacity: 1;
        margin: 20px;

        @include respond-to('md') {
            max-width: 500px;
            padding: 40px;
        }

        .title {
            font-size: 1.25rem;
            margin: 0 !important;
        }
        .message {
            font-size: $p_font_size_desktop;
            line-height: 1.41;
            letter-spacing: normal;
            color: #313131;
            margin: 20px 0 0 0 !important;
        }

        .button-container {
            display: flex;
            flex-direction: row;
            margin: 20px -20px 0 0 !important;
            flex-wrap: wrap;

            button {
                width: 100%;
                flex: 1;
                margin-right: 20px;
                margin-top: 20px;
                white-space: nowrap;
            }
        }
    }

    &.fadeIn {
        .backdrop {
            -webkit-animation: fadeIn 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: fadeIn 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
        .confirm-box {
            -webkit-animation: slide-up 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: slide-up 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
    }
    &.fadeOut {
        .backdrop {
            -webkit-animation: fadeOut 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: fadeOut 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
        .confirm-box {
            -webkit-animation: slide-down 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: slide-down 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        }
    }
}

.signup-form {
    .floating-label-input {
        margin-bottom: 15px;
    }
}

.grid.full-name-grid {
    @include respond-to('md') {
        grid-template-columns: 0.5fr 0.5fr;
        column-gap: 15px;
    }
}

.gray-link,
.gray-link:hover,
.gray-link:active,
.gray-link:visited,
.gray-link:focus {
    color: color(dark_gray);
    text-decoration: underline;
    cursor: pointer;
    font-family: $p_font_family;
    font-size: $p_font_size_mobile;
    @include respond-to('xl') {
        font-size: $p_font_size_desktop;
    }
    &.small {
        font-size: $p_small_font_size;
    }
}

/*
    usage:
    div.gray-circle

    the gray circle background, visible on the "for boligsøgende" page.
*/
.gray-circle {
    width: 162px;
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.gray-circle::after {
    content: '';
    width: 100%;
    padding-bottom: 100%;
    background: color(bg_gray);
    border-radius: 9999px;
    display: block;
}

.highlight-box {
    padding: 20px 24px;
    margin-bottom: 25px;
    @include border-radius;
    p:not(:last-child),
    strong:not(:last-child) {
        margin-bottom: 4px;
    }
}

/*
    usage:

    div.slide-show
        picture
            ...
        picture
            ...

*/
.slideshow {
    position: relative;

    picture:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
    }

    picture:not(.active) {
        opacity: 0;
    }

    picture {
        transition: opacity 500ms linear;
    }
}

$card-padding: 25px;

.flat-card {
    overflow: hidden;
    padding: 0;
    cursor: pointer;
    @include border-radius;
    background-color: color(white);
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.10);

    .card-hero {
        @include transition(all, 250ms, ease-in-out);
        .webp & {
            background-image: linear-gradient(
                    180deg,
                    rgba(1, 1, 1, 0) calc(100% - 120px),
                    rgba(1, 1, 1, 0.9) 100%
                ),
                var(--card-background-image-webp);
        }
        .no-webp & {
            background-image: linear-gradient(
                    180deg,
                    rgba(1, 1, 1, 0) calc(100% - 120px),
                    rgba(1, 1, 1, 0.9) 100%
                ),
                var(--card-background-image-jpg);
        }

        position: relative;
        padding: $card-padding 20px;
        min-height: 200px;
        display: flex;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        h3,
        h4,
        p {
            align-self: flex-end;
            margin: 0;
            color: color(white);
            user-select: none;
        }

        .subscribe-icon {
            width: 64px;
            height: 64px;
            position: absolute;
            bottom: $card-padding;
            right: $card-padding;
            padding: 20px;
            margin: -$card-padding;

            &.white-icon {
                opacity: 0;
                z-index: 1;
            }

            path {
                @include transition();
                fill: rgba(0, 0, 0, 0);
                stroke: color(white);
            }

            &:hover path {
                fill: transparentize(color(white), 0.5);
                stroke: color(white);
            }

            &.subscribed path {
                fill: color(salmon_red);
                stroke: color(salmon_red);
            }
        }

        .flex-between {
            width: 100%;
            h3 {
                line-height: 1;
            }
        }

        @include respond-to('lg') {
            background-size: 180% auto;
            &:hover {
                background-size: 190% auto;
            }
        }

        @include respond-to('xl') {
            background-size: 110% auto;
            &:hover {
                background-size: 120% auto;
            }
        }
    }

    .card-body {
        & > *:first-child {
            padding: 20px;
        }
        & > *:not(:first-child) {
            padding: 12px 0px;
        }
        text-align: center;
        user-select: none;

        a,
        p {
            font-weight: 600;
            text-decoration: none;
            text-align: center;
            color: color(black);
            font-size: $base_font_size;

            &.subscribed {
                color: color(black);
            }
        }

        a,
        p,
        span {
            &:not(:first-child) {
                border-top: 1px solid color(border_gray);
            }
        }

        & > .flex-between > * {
            display: flex;
            align-items: center;
            position: relative;
            line-height: 1;

            img {
                position: relative;
                height: 20px;
                right: 6px;
                margin-top: -10px;
                margin-bottom: -10px;
            }
        }
    }
}

/*
    div.payment-card-container
        img
        span
*/
.payment-card-container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: auto 1fr;
    align-items: center;
    column-gap: 10px;

    img {
        max-width: 40px;
    }
}

.lofi-placeholder {
    $base: 8;
    float: left;
    height: $base * 2 + px;
    width: $base * 4 + px;
    border-radius: $base + px;
    background: color(switch_gray);
    margin-top: 5px;
    margin-bottom: 5px;

    &:not(:first-of-type) {
        margin-left: 5px;
    }

    &.s {
        width: $base * 3 + px;
    }

    &.l {
        width: $base * 6 + px;
    }

    &.xl {
        width: $base * 9 + px;
    }

    &.full-width {
        width: 100%;
    }

    &.dark {
        background: color(border_gray);
    }
}

.credit-card {
    background: color(forest);
    max-width: 350px;
    height: auto;
    display: flex;
    font-family: monospace;
    color: white;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    margin-left: -15px;
    margin-right: -15px;

    .card-brand {
        align-self: flex-end;
        max-width: 40px;
    }
    .card-container {
        padding: 30px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .chip {
        width: 35px;
    }
    .card-number {
        font-size: 1.25rem;
        text-align: left;
        letter-spacing: 1px;
        font-family: monospace;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .card-info {
        display: flex;
        justify-content: space-between;
    }

    .card-info-item > .title {
        font-family: sans-serif;
        font-size: 0.5rem;
        text-align: left;
    }

    .card-info-item > .value {
        margin-top: 5px;
        font-size: 0.875rem;
        text-align: left;
        text-transform: uppercase;
    }

    @include respond-to('md') {
        width: 100%;
        margin-left: unset;
        margin-right: unset;
        .card-number {
            font-size: 1.25rem;
            letter-spacing: 2px;
        }
    }
}

/*
   *    CHECK LIST
   */

ul.checklist {
    padding-left: 0px;
    list-style-type: none;
    margin: 0px;
    font-size: $p_font_size_mobile;
    @include respond-to('xl') {
        font-size: $p_font_size_desktop;
    }

    li {
        position: relative;
        margin-bottom: 8px;
        line-height: 1.6;
        padding-left: 30px;
    }

    li::before {
        content: '';
        background-image: url('/static/icons/check.svg');
        background-repeat: no-repeat;
        background-position: left center;
        position: absolute;
        left: 0px;
        width: 24px;
        height: 100%;
    }
}

/*
 *      FAQ
 */

.faq-ul {
    padding: 0px;
    list-style-type: none;
    margin: -19px 0px 0px;

    .faq-title {
        display: flex;
        justify-content: space-between;
        padding: 25px 0px;

        h4 {
            font-family: $primary_font_family;
            margin-bottom: 0px;
            transition: all 0.2s ease;
            padding-bottom: 2px;
            max-width: calc(100% - 30px);
        }

        .fold-icon {
            display: block;
            position: relative;
            height: 20px;
            width: 20px;
            transform: scale(0.75);
        }
        .fold-icon::before,
        .fold-icon::after {
            content: '';
            position: absolute;
            @include border-radius;
            background-color: color(black);
            transition: all 0.2s ease;
        }

        .fold-icon::before {
            width: 100%;
            height: 2px;
            top: 12px;
        }

        .fold-icon::after {
            width: 2px;
            height: 100%;
            left: 9px;
            top: 3px;
        }
    }

    li {
        position: relative;
        padding: 0px;
        cursor: pointer;
        &:not(.open):not(.closed) .faq-content {
            max-height: 0px;
            border-color: transparent !important;
        }
        border-bottom: 1px solid color(border_gray);
    }

    .faq-content {
        overflow: hidden;
        transition: 0.2s all ease;

        & > div {
            padding-bottom: 25px;

            & > p {
                font-size: $base_font_size !important;
            }
        }
    }

    li.closed {
        .faq-content {
            height: 0px;
            margin-top: -1px;
        }
    }

    li.open {
        .faq-content {
            height: var(--height);
        }
        .fold-icon::after {
            background-color: transparent;
        }
        h4,
        .fold-icon::before {
            color: color(alligator);
        }
        .fold-icon::before {
            background-color: color(alligator);
        }
    }
}

.dropdown-menu-container {
    &:not(.expanded) .dropdown-menu {
        pointer-events: none;
        opacity: 0;
    }

    .dropdown-menu {
        min-width: 205px;
        background: #ffffff;
        color: #313131;
        position: absolute;
        transform: translateY(-5px);
        border-radius: 10px;
        padding: 30px;
        right: 0;
        box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
        transition: opacity 166ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
            transform 166ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

        div,
        p {
            cursor: pointer;
        }

        div:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.breadcrumb {
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
    align-items: center;
    justify-content: start;

    $color: color(waitinglist_gray);

    a {
        font-size: $p_small_font_size;
        text-decoration: none;
        color: $color;

        &:visited {
            color: $color;
        }
    }

    img {
        width: 7px;
    }
}
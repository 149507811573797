
h2 {
    @include small-h2;

    & + div {
        margin-top: 50px;
    }
}

h3 {
    margin-bottom: 40px;
}

.detailview-box {
    padding: 50px;
    background-color: color(white);
    margin-top: 50px;
    @include border-radius;

    & > *:first-child {
        margin-top: 0px;
    }
    & > *:last-child {
        margin-bottom: 0px;
    }

    &.hidden { display: none; }
}
.action-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    *:not(:last-child) {
        margin-right: 30px;
    }

    .more-btn {
        margin-left: auto;
        cursor: pointer;
    }
}

.text-coral {
    color: color(salmon_red);
}

.edit-param-grid {
    column-gap: 80px;
    row-gap: 20px;
    & > div {
        align-items: end;
        display: grid;
        grid-template-columns: 1.2fr 1fr;
        grid-template-rows: auto 1fr;
        border-bottom: 1px solid color(border_gray);
    }

    & > div > span:nth-child(2),
    & label {
        align-self: start;
        line-height: 25px;
    }

    & > div > span:nth-child(2) {
        text-align: right;
    }

    label {
        color: color(dark_gray);
        font-weight: 600;
    }

    .value {
        margin-top: 10px;
        margin-bottom: 20px;
        font-weight: 600;
    }

    .green-link {
        font-weight: 600;
    }
}

.new-external-list-pic-upload {
    cursor: pointer;
    @include border-radius;
    position: relative;
    background-color: color(bg_gray);
    border: 2px dashed color(border_gray);
    display: flex;
    overflow: hidden;
    @include transition;
    height: 360px;

    input {
        display: none;
    }

    button {
        position: absolute;
    }

    .overlay {
        @include transition;
        background: transparent;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &::after {
        content: '';
        background-image: url('/static/icons/camera.svg');
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
        background-size: contain;
        position: absolute;
        left: calc(50% - 20px);
        top: calc(50% - 20px);
        @include transition;
    }

    &:hover {
        background-color: darken(color(bg_gray), 2%);
    }

    // background image will be set inline by js
    &.uploaded {

        border: none;
        background-size: cover;

        &::after {
            background-image: url('/static/icons/editimage.svg');
            opacity: 0.0;
        }


        &:hover {
            .overlay {
                background-color: rgba(10, 10, 10, .3);
            }
            &::after {
                opacity: 1.0;
            }
        }

    }
}


/*
 *      Organization detail view
 */

 .detailview-box.org-information {
    .grid {
        grid-template-columns: 1fr 1fr;
    }
}

.detailview-box.org-related-users {
    & > .grid {
        grid-template-columns: 1fr 1fr;
        column-gap: 80px;
        row-gap: 25px;

        & > div {
            background-color: color(bg_gray);
            padding: 8px 20px;
            @include border-radius;
            display: grid;
            column-gap: 20px;
            grid-template-columns: 1fr 120px 30px;
            align-items: center;

            .remove-icon-btn {
                cursor: pointer;
            }

            p:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

.detailview-box.org-related-real-estates {
    & > .grid {
        grid-template-columns: 1fr 1fr;
        column-gap: 80px;
        row-gap: 25px;

        & > div {
            background-color: color(bg_gray);
            padding: 15px 20px;
            @include border-radius;
            display: grid;
            column-gap: 20px;
            grid-template-columns: 1fr 30px;
            align-items: center;

            .remove-icon-btn {
                cursor: pointer;
            }

            p:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

.detailview-box.org-plan {
    & > .grid {
        grid-template-columns: 1fr;
        row-gap: 25px;

        & > div {
            background-color: color(bg_gray);
            padding: 15px 20px;
            @include border-radius;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        & > div.selected {
            color: color(white);
            p {
                color: color(white);
            }
            background-color: color(forest);
        }
    }
}
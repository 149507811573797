$env: undefined;
@import '../../../shared/frontend/scss/responsiveness';
@import '../../../shared/frontend/scss/mixins';
@import '../../../shared/frontend/scss/fonts';
@import '../../../shared/frontend/scss/colors';
@import '../../../shared/frontend/scss/classes';
@import '../../../shared/frontend/scss/elements';
@import '../../../shared/frontend/scss/modal';
@import '../../../shared/frontend/scss/microtip';

html {
    height: 100%;
}

body {
    margin: 0;
    padding: 0px 0px 0px 0px;
    min-height: 100%;
    box-sizing: border-box;
    background-color: color(bg_gray);
    overflow-x: hidden;
    color: color(black);

    * {
        box-sizing: border-box;
    }
}

@import 'pages/login', 'layout', 'elements', 'searchbar', 'modals';
@import 'organization', 'user', 'real-estate', 'interest-lists';

.edit-plan-modal {
    .grid {
        grid-template-columns: 50px 2fr;
        row-gap: 25px;
    }

    .grid > div {
        display: flex;
        align-items: center;
    }

    .floating-label-input {
        margin-bottom: 0px;
        width: 100%;
    }

    .radiobtn {
        margin: 0px !important;
        transform: translateY(-14px);
    }
}
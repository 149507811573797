
/* @include transition(all,2s,ease-out); */
@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {
    -webkit-transition: $what $time $how;
    -moz-transition:    $what $time $how;
    -ms-transition:     $what $time $how;
    -o-transition:      $what $time $how;
    transition:         $what $time $how;
}

// TODO
@mixin border-radius($size: 4px) {
    border-radius: $size;
}

// TODO;
@mixin box-shadow($top: 0, $left: 0, $blue_radius: 100px, $spread_radius: 0, $color: rgba(0, 0, 0, 0.1)) {
    box-shadow: $top $left $blue_radius $spread_radius $color;
}

@mixin box-shadow-small(){
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.10);
}

@mixin _gray_dashed_box(){
    cursor: pointer;
    @include border-radius;
    @include transition;
    border: 1px dashed #ccc;
    background-color: rgba(0,0,0,.02);
    color: color(dark_gray);
    font-size: $btn_font_size;
    font-weight: 600;
    &:hover {
        background-color: rgba(0,0,0,.04);
    }
}

@mixin gray_dashed_box() {
    @include _gray_dashed_box;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@mixin gray_dashed_box_noflex(){
    @include _gray_dashed_box;
}

/*
    header_img :: (Selector, FileName) -> ()

    usage:

    header:before {
        @include header_img('bg-01');
    }
*/

@mixin header_img($selector, $src){
    .webp #{$selector}::before {
        background-image: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 0.7) 0%,
                        rgba(0, 0, 0, 0.1) 100%
        ),
        url('/static/images/#{$src}.webp');
    }

    .no-webp #{$selector}::before {
        background-image: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 0.7) 0%,
                        rgba(0, 0, 0, 0.1) 100%
        ),
        url('/static/images/#{$src}.jpg');
    }

    #{selector}::before {
        background-position: center;
        background-size: cover;
    }
}

@mixin header_img_var($selector, $var: --header-background-image){
    #{$selector} {
        background-image: linear-gradient(
                        180deg,
                        rgba(0, 0, 0, 0.7) 0%,
                        rgba(0, 0, 0, 0.1) 100%
        ),
        var($var);

        background-position: center;
        background-size: cover;
    }
}
.modal-overlay {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: space-around;
    background-color: color(modal_bg);
    overflow-y: scroll;
    transition: opacity 0.35s ease-in-out, z-index 0.35s step-start;
    -webkit-transition: opacity 0.35s ease-in-out, z-index 0.35s step-start;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.opened {
        & > div {
            transform: translateY(0px);
        }
    }

    &.closing {
        opacity: 0;
        z-index: 120;
    }
    &.opened {
        z-index: 120;
        opacity: 1;
    }
    &.closed {
        z-index: -10;
        opacity: 0;
        visibility: hidden;
    }

    @include respond-to('sm') {
        align-items: flex-start;
    }

    & > div {
        width: 100%;
        background-color: white;
        padding: 40px;
        border-radius: 4px;
        position: relative;
        transform: translateY(40px);
        @include transition(
            transform,
            0.3s,
            cubic-bezier(0.25, 0.46, 0.45, 0.94)
        );

        @include respond-to('sm') {
            padding: 35px 20px 50px 20px;
            min-height: 100%;
        }
        @include respond-to('md') {
            max-width: 500px;
        }
        @include respond-to('xl') {
            // margin-bottom: 100px;
        }

        .close {
            position: absolute;
            background-image: url('/static/icons/close.svg');
            background-repeat: no-repeat;
            background-size: contain;
            width: 20px;
            height: 20px;
            right: 30px;
            top: 30px;
            cursor: pointer;
            @include transition();

            &:hover {
                opacity: 0.5;
            }
        }

        & > p {
            margin-bottom: 25px;
        }

        label {
            margin-bottom: 10px;
            color: color(dark_gray);
            display: block;
            font-family: 'proxima-nova', sans-serif;
            font-weight: $default_font_weight;
        }

        .radiobtn,
        input[type='text'],
        input[type='password'] {
            margin-bottom: 35px;
            margin-top: 0px;
        }

        button,
        input[type='button'],
        input[type='submit'],
        input[type='text'],
        input[type='password'],
        input[type='tel'],
        input[type='email'] {
            display: block;
            width: 100%;
        }

        & > button:last-child,
        & > input[type='button']:last-child,
        & > input[type='submit']:last-child {
            margin-top: 40px;
        }

        a,
        a:hover,
        a:visited,
        a:focus,
        a:active {
            color: inherit;
            text-decoration: underline;
        }

        .check-cirle {
            display: block;
            margin: 30px auto 5px;
        }

        .error {
            margin-bottom: 20px;
            display: block;

            > span {
                display: block;

                &:not(:last-of-type) {
                    margin-bottom: 10px;
                }
            }
        }

        .btn-usertype {
            display: flex;
            justify-content: left;
            align-items: center;
            background-color: transparent;
            padding: 29px 30px 29px 30px;
            @include border-radius(4px);
            border: 1px solid color(border_gray);
            @include transition();
            font-family: $header_font_family;
            cursor: pointer;

            &:last-child {
                margin-top: 25px;
            }

            img {
                margin-right: 20px;
                max-width: 40px;
            }
            h3 {
                margin: 0;
            }

            &:hover {
                background-color: color(bg_gray);
            }
        }

        .custom-select {
            margin-bottom: 23px;
        }

        textarea {
            resize: vertical;
            min-height: 200px;
            margin-bottom: 25px;
            font-size: inherit;
        }

        .hidden {
            display: none;
        }
    }

    &#login,
    &#partner-login,
    &#forgot-password,
    &#reset-password {
        .btn1 {
            background-color: color(alligator);
        }
        .open-modal-link {
            margin-top: 20px;
            width: 100%;
            text-align: center;
            a {
                color: color(dark_gray);
            }
            a:hover {
                cursor: pointer;
            }
        }
    }

    & > div.modal-slim {
        max-width: 500px;
    }

    & > div.modal-wide {
        max-width: 560px;
    }

    .choose-block {
        border-bottom: 1px solid color(border_gray);
        padding-bottom: 30px;

        div {
            @include border-radius;
            display: block;
            background-color: color(bg_gray);
            padding: 15px 20px;
            margin-top: 16px;
            position: relative;
            cursor: pointer;
            @include transition;

            &:hover {
                background-color: darken(color(bg_gray), 2%);
            }
        }
        div.selected {
            color: color(white);
            background-color: color(forest);

            &::after {
                content: '';
                position: absolute;
                right: 16px;
                width: 18px;
                height: 18px;
                background-image: url('/static/icons/check-white.svg');
            }
        }
    }

    .modal-table > div {
        display: flex;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid color(border_gray);
        justify-content: space-between;
        cursor: pointer;
        position: relative;

        &:hover {
            &::before {
                content: '';
                position: absolute;
                left: -12px;
                border-top: 6px solid transparent;
                border-bottom: 6px solid transparent;
                border-left: 8px solid #313131;
                top: 12px;
            }
        }
    }
}


$sidebar_width: 300px;
$sidebar_padding_size: 50px;

.sidebar {
    background-color: #fff;
    height: 100%;
    position: fixed;
    width: $sidebar_width;
    top: 0px;
    left: 0px;
    padding: $sidebar_padding_size 0px $sidebar_padding_size $sidebar_padding_size;
    display: flex;
    flex-direction: column;

    .navlogo { max-width: 130px; }

    ul {
        list-style-type: none;
        margin-top: 70px;
        padding: 0px;
    }

    li {
        padding: 8px 0px;
        a {
            font-size: $fonts_nav_mobile;
            color: color(black);
            @include transition();
            padding: 7px 0px;
            display: block;
            text-decoration: none;
            border-right: 3px solid transparent;

            &:hover {
                color: color(alligator);
            }
        }
        &.active a {
            color: color(alligator);
            border-right-color: color(alligator);
        }
    }

    .sidebar-menu {
        flex: 1;
    }

    .sidebar-footer {
        margin-left: -$sidebar_padding_size;
        margin-bottom: -$sidebar_padding_size;
        border-top: 1px solid color(border_gray);

        .sidebar-footer-content {
            padding-top: calc(#{$sidebar_padding_size} * 0.5);
            padding-bottom: calc(#{$sidebar_padding_size} * 0.5);
            padding-left: $sidebar_padding_size;
            padding-right: $sidebar_padding_size;

            .username {
                font-size: $base_font_size;
                color: color(black);
                font-weight: 600;
            }

            .logout {
                font-size: $base_font_size;
                margin-top: 10px;
                display: block;
                color: color(dark_gray);
                text-decoration: none;
                &:visited {
                    color: color(dark_gray);
                }
            }
        }
    }
}

main {
    position: absolute;
    left: $sidebar_width;
    width: calc(100% - #{$sidebar_width});
    display: flex;
    flex-direction: column;
    padding: 50px 60px;
}


/*
 *      User detail view
 */

.detailview-box {
    &.user-information {
        .grid {
            grid-template-columns: 1fr 1fr;
        }
    }

    &.user-organizations {
        & > .grid {
            grid-template-columns: 1fr 1fr;
            column-gap: 80px;
            row-gap: 25px;

            & > div {
                background-color: color(bg_gray);
                padding: 15px 20px;
                @include border-radius;
                cursor: pointer;

                &:hover p {
                    text-decoration: underline;
                }
            }
        }
    }

    &.user-spots {
        & > .grid {
            @include transition(all, 0.12s, ease);
            @include border-radius();
            
            grid-template-columns: 3fr 3fr 1fr 1fr;
            margin-top: 0.5rem;
            padding: 15px 20px;

            &.header {
                font-weight: 600;
            }

            &:not(.header) {
                background-color: color(bg_gray);
                &:hover {
                    background-color: color(dark_bg_gray);
                }
            }

            a {
                color: inherit;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }

            > img {
                height: 100%;
                justify-self: end;
                cursor: pointer;
            }
        }
    }
}